import { Component, Input } from '@angular/core';
import { AddressType } from '@app/app/common/model/address-type';
import { TagManagerService } from '@app/app/common/tag.service';

import { forkJoin, Observable, of } from 'rxjs';

import { TranslateExtendedService } from 'src/app/common/translate-extended.service';
import { ConfigurationService } from 'src/app/config/configuration.service';
import { Address } from 'src/app/gis/model/poidetail/address';
import { PoiDetail } from 'src/app/gis/model/poidetail/poidetail';

@Component({
  selector: 'app-addressing',
  templateUrl: './addressing.component.html',
  styleUrls: ['./addressing.component.scss'],
})
export class AddressingComponent {
  public address: Address;
  public anredeDropDownVal: string = 'nochoice';
  public nameVorname = '';
  public userId = '';
  public klpUrl = '';
  public addressType = AddressType;

  private _at: AddressType;
  private _poi: PoiDetail;

  constructor(
    private translateExtendedService: TranslateExtendedService,
    private configurationService: ConfigurationService,
    private readonly tagManagerService: TagManagerService
  ) {
    this.klpUrl = this.configurationService.getConfiguration().klpUrl;
  }

  // address type
  public get at() {
    return this._at;
  }
  public set at(val: AddressType) {
    this._at = val;
    this.tabChanged();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public get poi() {
    return this._poi;
  }
  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Input()
  public set poi(poi: PoiDetail) {
    this._poi = poi;
    if (this.hasPickPostAddress()) {
      this.address = this._poi.pickpostAddress;
      this.at = AddressType.PICKPOST;
    } else if (this.hasPostlagerndAddress()) {
      this.address = this._poi.postlagerndAddress;
      this.at = AddressType.POST_LAGERND;
    } else if (this.hasMyPost24Address()) {
      this.address = this._poi.myPost24Address;
      this.at = AddressType.MY_POST_24;
    }
  }

  public trackLinkClick(event: Event) {
    const target = (event.currentTarget as HTMLAnchorElement).href;

    this.translateExtendedService.get('detail.addressing.wtfIsUserId').subscribe((linkText) => {
      /* eslint-disable @typescript-eslint/naming-convention */
      this.tagManagerService.click({
        event: 'link_click',
        link_text: linkText.toLowerCase(),
        link_url: target,
        label: 'user-id-kundenlogin-hinweis',
        type: 'link',
        additional_info: `tab-adressierung`
      });
      /* eslint-enable @typescript-eslint/naming-convention */
    }
    );
  }

  public hasPostlagerndAddress(): boolean {
    if (this.poi.postlagerndAddress) {
      return true;
    }
    return false;
  }

  public hasPickPostAddress(): boolean {
    if (this.poi.pickpostAddress) {
      return true;
    }
    return false;
  }

  public hasMyPost24Address(): boolean {
    if (this.poi.myPost24Address) {
      return true;
    }
    return false;
  }

  public hasAnyAddress(): boolean {
    return this.poi && (this.hasPickPostAddress() || this.hasPostlagerndAddress() || this.hasMyPost24Address());
  }

  public hasUserId(): boolean {
    return this.at === AddressType.PICKPOST || this.at === AddressType.MY_POST_24;
  }

  public getTitleForAddressType(): string {
    switch (this.at) {
      case AddressType.PICKPOST:
        return 'detail.addressing.addressPickPost';
      case AddressType.POST_LAGERND:
        return 'detail.addressing.addressPostlagernd';
      case AddressType.MY_POST_24:
        return 'detail.addressing.addressMypost24';
    }
    return '';
  }

  public getAnrede(): Observable<string> {
    switch (this.anredeDropDownVal) {
      case 'male':
        return this.translateExtendedService.get('detail.addressing.male');
      case 'female':
        return this.translateExtendedService.get('detail.addressing.female');
      case 'family':
        return this.translateExtendedService.get('detail.addressing.family');
    }
    return of('');
  }

  public hasAnrede(): boolean {
    return this.anredeDropDownVal !== 'nosalutation';
  }

  public showAnredePlaceholder(): boolean {
    return this.anredeDropDownVal === 'nochoice' || this.anredeDropDownVal === undefined;
  }

  public showVornameNachnamePlaceholder(): boolean {
    return this.nameVorname.match(/^ *$/) !== null;
  }

  public showUserIdPlaceholder(): boolean {
    return this.userId.match(/^ *$/) !== null;
  }

  private tabChanged() {
    switch (this._at) {
      case AddressType.PICKPOST:
        this.address = this.poi.pickpostAddress;
        break;
      case AddressType.POST_LAGERND:
        this.address = this.poi.postlagerndAddress;
        break;
      case AddressType.MY_POST_24:
        this.address = this.poi.myPost24Address;
        break;
    }
  }
}
