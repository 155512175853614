import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root',
})
export class RoutingService {
  constructor(private activatedRoute: ActivatedRoute, private logger: NGXLogger) {}

  public getQueryParam(queryParamKey: string): string {
    let paramValue;

    // get query parameter case sensitive
    this.activatedRoute.queryParamMap.subscribe((params) => {
      params.keys.forEach((key) => {
        if (key.toLowerCase() === queryParamKey) {
          paramValue = params.get(key);
          this.logger.debug(`Handled param ${key}: ${paramValue}`);
        }
      });
    });

    return paramValue;
  }

  public getNeedIdParam(): string {
    return this.getQueryParam('needid');
  }
}
